import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { HoperfyEvent } from '../../clients/DirectusClient';

interface NavigationProps {
  event: HoperfyEvent | null
}

const HotelView: FC<NavigationProps> = ({ event }) => {
  ;
  return <div>
    {event && (
      <nav className='w-full pt-6 flex flex-col items-center px-10 pb-6 relative overflow-hidden' style={{ backgroundColor: event.cover_color }}>

        <div className="w-full">
          <div className="relative z-10 flex md:flex-row flex-col gap-10 items-center justify-between">
            <div className="flex items-center gap-2">
              <Link className="flex flex-col rounded-3xl relative group" to={`/${event.slug}/hotels`}>
                <img className="h-6" src="https://assets.ycodeapp.com/assets/app26596/Images/7JlpSt7MuAiN35GG0HJvk5JanUiKeguhTQyNs95s-published.png" alt='Hoperfy logo' />
              </Link>
              {event.logo &&
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6 text-white opacity-30">
                  <path fillRule="evenodd" d="M12.528 3.047a.75.75 0 0 1 .449.961L8.433 16.504a.75.75 0 1 1-1.41-.512l4.544-12.496a.75.75 0 0 1 .961-.449Z" clipRule="evenodd"></path>
                </svg>
              }{event.logo &&
                <div className="flex items-center gap-3">
                  <img className={event.logo_css_class} src={process.env.REACT_APP_DIRECTUS_BASE_URL + '/assets/' + event.logo} alt='Sigma logo' />
                </div>
              }
            </div>
            <div className="flex items-center gap-5">
              <div className="flex items-center gap-2">
                <div className="flex flex-col">
                  <span className="text-white font-semibold tracking-tight">{event.city + ", " + event.country}</span>
                  <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.venue)}`} target="_blank" rel="noreferrer" className="text-xs underline underline-offset-2 text-white font-semibold opacity-70 ">{event?.venue}</a>
                </div>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6 text-white opacity-50">
                <path fillRule="evenodd" d="M12.528 3.047a.75.75 0 0 1 .449.961L8.433 16.504a.75.75 0 1 1-1.41-.512l4.544-12.496a.75.75 0 0 1 .961-.449Z" clipRule="evenodd"></path>
              </svg>
              <div className="flex flex-col">
                <span className="text-white font-semibold tracking-tight">{new Date(event.start_date).toISOString().substring(8, 10) + " - " + new Date(event.end_date).toISOString().substring(8, 10)}</span>
                <span className="text-xs text-white font-medium opacity-70">{new Date(event.start_date).toLocaleString('default', { month: 'long' })} {new Date(event.start_date).getFullYear()}</span>
              </div>
            </div>
          </div>

          <img src={process.env.REACT_APP_DIRECTUS_BASE_URL + '/assets/' + event.banner} alt='event-banner' className="absolute h-full w-full left-0 object-cover top-0" style={{ opacity: event.banner_opacity }} />
          <hr className="w-full border-black/5 mt-10" />

        </div>

      </nav>
    )}
  </div>
}


export default HotelView;
