export function calculateNights(startUTCDate: Date, endUTCDate: Date) {
    const startUTC = startUTCDate as unknown as string;
    const endUTC = endUTCDate as unknown as string;
    // Parse the dates as UTC (by appending "Z" if they're missing a timezone)
    const start = new Date(`${startUTC}Z`);;
    const end   = new Date(`${endUTC}Z`);
    
    // Convert each date to the number of whole UTC days since the Unix epoch
    const startDay = Math.floor(start.getTime() / 86400000); // 24*60*60*1000
    const endDay   = Math.floor(end.getTime() / 86400000);
    
    // The difference is how many midnights are crossed
    const nights = endDay - startDay;
    
    // If end date is before start date, we might get a negative or zero value;
    // use Math.max() to ensure at least 0 if needed:
    return Math.max(nights, 0);
  }