import { InfoWindow, useMap } from '@vis.gl/react-google-maps';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { type Marker, MarkerClusterer, Cluster, Renderer } from '@googlemaps/markerclusterer';
import { HotelResponse } from '../../clients/HotelsClient';
import HotelMarker from '../HotelMarker/HotelMarker';
import { HoperfyEvent } from '../../clients/DirectusClient';

export type ClusteredHotelMarkerProps = {
  hotels: HotelResponse[];
  onClick: (hotel: HotelResponse) => void;
  activeHotelId: string,
  event: HoperfyEvent
};

/**
 * The ClusteredTreeMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
const ClusteredHotelMarker: FC<ClusteredHotelMarkerProps> = ({ hotels, onClick, activeHotelId, event }: ClusteredHotelMarkerProps) => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const [selectedHotelId, setSelectedTreeKey] = useState<string | null>(null);

  const selectedTree = useMemo(
    () =>
      hotels && selectedHotelId
        ? hotels.find(hotel => hotel.hotel.id === selectedHotelId)!
        : null,
    [hotels, selectedHotelId]
  );

  const customRenderer: Renderer = {
    render: ({ count, position }: Cluster) => {
      // Create the main cluster container
      const div = document.createElement('div');
      div.style.display = 'flex';
      div.style.alignItems = 'center';
      div.style.justifyContent = 'center';
      div.style.borderRadius = '50%';
      div.style.color = 'white';
      div.style.fontWeight = 'bold';

      // Apply style based on the cluster size
      if (count < 5) {
        div.style.width = '25px';
        div.style.height = '25px';
        div.style.backgroundColor = '#8e8e8e'; // Small cluster - Blue
        div.style.fontSize = '10px';
      } else if (count < 10) {
        div.style.width = '45px';
        div.style.height = '45px';
        div.style.backgroundColor = '#8e8e8e'; // Medium cluster - Green
        div.style.fontSize = '12px';
      } 
      else if (count < 30) {
        div.style.width = '60px';
        div.style.height = '60px';
        div.style.backgroundColor = '#8e8e8e'; // Large cluster - Red
        div.style.fontSize = '14px';
      }
      else {
        div.style.width = '60px';
        div.style.height = '60px';
        div.style.backgroundColor = '#8e8e8e'; // Large cluster - Red
        div.style.fontSize = '14px';
      }

      div.textContent = count.toString();

      // Return a new AdvancedMarkerElement for this cluster
      return new google.maps.marker.AdvancedMarkerElement({
        position,
        content: div
      });
    },
  };
  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({
      map,
      renderer: customRenderer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers(markers => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedTreeKey(null);
  }, []);


  return (
    <>
      {hotels.map(hotel => (
        <HotelMarker
          key={hotel.hotel.id}
          hotel={hotel}
          onClick={onClick}
          setMarkerRef={setMarkerRef}
          activeHotelId={activeHotelId}
          event={event}
        />
      ))}

      {selectedHotelId && (
        <InfoWindow
          anchor={markers[selectedHotelId]}
          onCloseClick={handleInfoWindowClose}>
          {selectedTree?.hotel.name}
        </InfoWindow>
      )}
    </>
  );

};

export default ClusteredHotelMarker