import type { Marker } from '@googlemaps/markerclusterer';
import React, { FC, useCallback } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { HotelResponse } from '../../clients/HotelsClient';
import { getTotalPrice } from '../../utils/PriceUtils';
import { HoperfyEvent } from '../../clients/DirectusClient';

export type HotelMarkerProps = {
    hotel: HotelResponse;
    onClick: (hotel: HotelResponse) => void;
    setMarkerRef: (marker: Marker | null, key: string) => void;
    activeHotelId: string,
    event: HoperfyEvent
};

const HotelMarker: FC<HotelMarkerProps> = (props: HotelMarkerProps) => {
    const { hotel, onClick, setMarkerRef, activeHotelId } = props;

    const handleClick = useCallback(() => onClick(hotel), [onClick, hotel]);
    const ref = useCallback(
        (marker: google.maps.marker.AdvancedMarkerElement) =>
            setMarkerRef(marker, hotel.hotel.id),
        [setMarkerRef, hotel.hotel.id]
    );

    return (
        <AdvancedMarker position={{ lat: hotel.hotel.contactDTO.coordinatesDTO.latitude, lng: hotel.hotel.contactDTO.coordinatesDTO.longitude }} ref={ref} onClick={handleClick}>
               <div className="relative flex items-center justify-center">
        {/* SVG Icon */}
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`size-14 ${activeHotelId === hotel.hotel.id ? `text-blue-500` : `text-gray-500`}`}>
            <path fillRule="evenodd"
                  d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                  clipRule="evenodd"/>
        </svg> */}
         {/* Price Overlay */}
         <div className={`${activeHotelId === hotel.hotel.id ? `bg-blue-300` : `bg-white`} absolute px-2 py-0.5 rounded-full text-sm font-bold text-black shadow-md`}>
            {hotel.dailyPricesStartFrom.currency.currencySymbol}{getTotalPrice(hotel.dailyPricesStartFrom, props.event).amount.toString()}
        </div>
        
    </div>
        </AdvancedMarker>
    );
};

export default HotelMarker;