import { FC, useState, MouseEvent } from 'react';
import { createFeedback } from '../../clients/DirectusClient';

interface FeedbackRequest {
    email: string;
    title: string;
    message: string;
}

interface ToastState {
    mounted: boolean;    // Whether the toast is rendered in the DOM
    visible: boolean;    // Whether the toast is visible (opacity-100) or hidden (opacity-0)
    message: string;
    isError: boolean;
}

const Feedback: FC = () => {
    const [open, setOpen] = useState(false);

    // Form fields (controlled inputs)
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");

    // Toast state (with mount + visibility)
    const [toast, setToast] = useState<ToastState>({
        mounted: false,
        visible: false,
        message: "",
        isError: false,
    });

    /**
     * showToast:
     * 1) Mount the toast in the DOM and make it visible.
     * 2) After 3 seconds, fade+scale it out.
     * 3) After fade out (300ms), unmount from DOM.
     */
    const showToast = (message: string, isError: boolean) => {
        // Step 1: Mount + become visible
        setToast({
            mounted: true,
            visible: true,
            message,
            isError,
        });

        // Step 2: Wait 3s, then fade out
        setTimeout(() => {
            setToast((prev) => ({ ...prev, visible: false }));
            // Step 3: After fade+scale out (300ms), unmount from DOM
            setTimeout(() => {
                setToast((prev) => ({ ...prev, mounted: false }));
            }, 300);
        }, 3000);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            // Create feedback
            const feedbackRequest: FeedbackRequest = { email, title, message };
            await createFeedback(feedbackRequest);

            // Success: show "Feedback sent", close modal
            showToast("Feedback sent", false);
            setOpen(false);

            // Optionally clear fields
            setEmail("");
            setTitle("");
            setMessage("");
        } catch (error) {
            console.error("Failed to submit feedback:", error);
            // Failure: show error toast, keep modal open
            showToast("Oops, something went wrong", true);
        }
    };

    // Close modal if user clicks the background overlay
    const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            setOpen(false);
        }
    };

    // Icons for success/error (sized to h-6 w-6)
    const SuccessIcon = (
        <svg
            className="h-6 w-6 flex-shrink-0"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
    );

    const ErrorIcon = (
        <svg
            className="h-6 w-6 flex-shrink-0"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 5.75h1.5v6h-1.5v-6Zm.75 8.5a.75.75 0 1 1 0 1.501.75.75 0 0 1 0-1.5Z"
            />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12c0 5.376 4.374 9.75 9.75 9.75s9.75-4.374 9.75-9.75S17.376 2.25 12 2.25 2.25 6.624 2.25 12Z"
            />
        </svg>
    );

    return (
        <>
            {/* Floating Question Mark Button (bottom-right, black style) */}
            <button
                onClick={() => setOpen(true)}
                className="
          fixed
          bottom-4
          right-14
          z-50
          flex
          items-center
          justify-center
          w-12
          h-12
          rounded-full
          bg-black
          hover:bg-black/70
          text-white
          text-2xl
          font-bold
          transition-colors
        "
            >
                ?
            </button>

            {/* Sleek Toast Notification (fade+scale in/out) */}
            {toast.mounted && (
                <div
                    className={`
      fixed
      top-5
      right-5
      z-[9999]
      transform
      px-5
      py-3
      rounded-2xl
      shadow-lg
      text-white
      flex
      items-center
      gap-3
      text-base
      font-medium
      leading-none
      transition-all
      ${toast.isError
                            ? "bg-gradient-to-r from-red-600 to-red-500"
                            : "bg-gradient-to-r from-green-600 to-green-500"
                        }
      ${toast.visible
                            ? "opacity-100 scale-100 duration-700 ease-in-out"
                            : "opacity-0 scale-95 duration-300 ease-in-out pointer-events-none"
                        }
    `}
                >
                    {toast.isError ? ErrorIcon : SuccessIcon}
                    <span>{toast.message}</span>
                </div>
            )}



            {/* Centered Modal (only rendered if open) */}
            {open && (
                <div
                    className="
            fixed
            inset-0
            z-50
            flex
            items-center
            justify-center
            bg-black/40
            p-4
          "
                    onClick={handleOverlayClick}
                >
                    {/* Modal Container (stopPropagation so clicks inside don't close it) */}
                    <div
                        className="
              relative
              w-full
              sm:w-auto
              max-w-md
              bg-white
              rounded-2xl
              shadow
              max-h-[100vh]
              overflow-y-auto
            "
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Header */}
                        <div className="flex items-center justify-between p-6 border-b border-black/10">
                            <h3 className="text-xl font-bold tracking-tight text-gray-800">
                                Send us your feedback
                            </h3>
                            <button
                                onClick={() => setOpen(false)}
                                className="
                  text-gray-400
                  bg-transparent
                  hover:bg-gray-200
                  hover:text-gray-900
                  rounded-lg
                  text-sm
                  w-8
                  h-8
                  inline-flex
                  justify-center
                  items-center
                  transition-colors
                "
                            >
                                {/* Close icon */}
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        {/* Feedback Form */}
                        <form onSubmit={handleSubmit} className="p-6 flex flex-col gap-6">
                            {/* Structured, multi-line text with bold highlights */}
                            <div className="text-base text-gray-700 leading-relaxed space-y-3">
                                <p>
                                    If you have any <strong>questions or need help with an issue</strong>, let us know below.
                                </p>
                                <p>
                                    Have <strong>feature requests</strong> you’d like to see? We’d love to hear about them!
                                </p>
                                <p>
                                    Spotted any <strong>bugs</strong>? Please let us know so we can fix them as soon as possible.
                                </p>
                            </div>

                            {/* Email */}
                            <div className="flex flex-col pt-3.5 relative">
                                <label
                                    htmlFor="email"
                                    className="text-xs opacity-60 px-5 relative z-10"
                                >
                                    Email
                                </label>
                                <input
                                    required
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="your@example.com"
                                    className="
                    font-medium
                    text-sm
                    bg-neutral-100
                    pb-3.5
                    pt-8
                    px-5
                    -mt-8
                    w-full
                    focus:outline-none
                    focus:bg-white
                    rounded-2xl
                    border
                    border-transparent
                    focus:border-neutral-200
                    leading-5
                  "
                                />
                            </div>

                            {/* Title */}
                            <div className="flex flex-col pt-3.5 relative">
                                <label
                                    htmlFor="title"
                                    className="text-xs opacity-60 px-5 relative z-10"
                                >
                                    Title
                                </label>
                                <input
                                    required
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Feedback Title"
                                    className="
                    font-medium
                    text-sm
                    bg-neutral-100
                    pb-3.5
                    pt-8
                    px-5
                    -mt-8
                    w-full
                    focus:outline-none
                    focus:bg-white
                    rounded-2xl
                    border
                    border-transparent
                    focus:border-neutral-200
                    leading-5
                  "
                                />
                            </div>

                            {/* Message */}
                            <div className="flex flex-col pt-3.5 relative">
                                <label
                                    htmlFor="message"
                                    className="text-xs opacity-60 px-5 relative z-10"
                                >
                                    Message
                                </label>
                                <textarea
                                    required
                                    id="message"
                                    name="message"
                                    rows={4}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Your message..."
                                    className="
                    font-medium
                    text-sm
                    bg-neutral-100
                    pb-3.5
                    pt-8
                    px-5
                    -mt-8
                    w-full
                    focus:outline-none
                    focus:bg-white
                    rounded-2xl
                    border
                    border-transparent
                    focus:border-neutral-200
                    leading-5
                  "
                                />
                            </div>

                            {/* Submit Button (slightly bigger font) */}
                            <button
                                type="submit"
                                className="
                  mt-2
                  p-5
                  bg-black
                  hover:bg-black/70
                  cursor-pointer
                  rounded-2xl
                  text-white
                  text-base
                  font-medium
                  transition-colors
                "
                            >
                                Submit Feedback
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default Feedback;
