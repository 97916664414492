import Big from "big.js";
import { UUID } from "crypto";

export interface Coordinates {
    latitude: number;
    longitude: number;
}

export interface Room {
    adults: number;
    children: number[];
}

export interface GeoSearchRequest extends BaseSearchRequest {
    longitude: string;
    latitude: string;
    radius: number;
}

export interface HotelSearchRequest extends BaseSearchRequest {
    hotelId: UUID;
}

export interface BaseSearchRequest {
    checkIn: Date;
    checkOut: Date;
    rooms: Room[];
    currency: string
}

export interface Hotel {
    id: UUID;
    createdAt: string;
    updatedAt: string;
    status: string;
    name: string;
    externalId: string;
    contactDTO: ContactDTO;
    checkInTime: string;
    checkOutTime: string;
    descriptions: Description[];
    stars: number;
    images: string[];
    roomDetails: string | null;
    locale: string;
    latitude: number;
    longitude: number;
    amenities: string[];
    rating: Rating | null;
}

export interface ContactDTO {
    address: string;
    coordinatesDTO: Coordinates;
}

export interface Description {
    title: string;
    description: string;
}

export interface RoomType {
    roomId: string;
    name: string;
    beddingType: string;
    breakfastIncluded: boolean;
    freeCancellationUntil: Date;
    hotelName: string;
    amenities: string[];
    images: string[];
    roomInfoList: RoomInfo[];
}

export interface RoomInfo {
    searchHash: string;
    roomsLeft: number;
    price: Price;
    pricePerNight: Price;
}

export interface Price {
    amount: Big;
    currency: Currency;
}

export interface Currency {
    currencyCode: string;
    currencySymbol: string
}

export interface Rating {
    id: string;
    createdAt: string;
    updatedAt: string;
    status: string;
    rating: number;
}

export interface HotelResponse {
    hotel: Hotel;
    roomTypeList: RoomType[];
    startingFrom: Price;
    currency: string;
    dailyPricesStartFrom: Price;
    roomsAvailable: number;
}


export interface CurrencyResponse {
    currencyCode: string
    currencySymbol: string
}


// const API_KEY = 'BHrU4U4LP*AoV3UW!ijzxeAKsqEKr7NFZ.'; // Replace with your actual API key
const BASE_URL = process.env.REACT_APP_HOPERFY_HOTEL_API_BASE_URL;
const API_KEY = process.env.REACT_APP_HOPERFY_HOTEL_API_KEY

export const geoSearch = async (searchData: GeoSearchRequest): Promise<HotelResponse[]> => {
    try {
        const response = await fetch(BASE_URL + "/hotels/search/geo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': `${API_KEY}`,
            },
            body: JSON.stringify(searchData),
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: HotelResponse[] = await response.json();
        return data;
    } catch (error) {
        console.error('Error searching hotels:', error);
        throw error;
    }
};


export const hotelSearch = async (searchData: HotelSearchRequest): Promise<HotelResponse> => {
    try {
        const response = await fetch(BASE_URL + '/hotels/search/hotel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': `${API_KEY}`,
            },
            body: JSON.stringify(searchData),
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: HotelResponse = await response.json();
        return data;
    } catch (error) {
        console.error('Error searching hotels:', error);
        throw error;
    }
};

export const getCurrencies = async(): Promise<CurrencyResponse[]> => {
    try {
        const response = await fetch(BASE_URL + '/currencies', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': `${API_KEY}`,
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: CurrencyResponse[] = await response.json();
        return data;
    } catch (error) {
        console.error('Error searching hotels:', error);
        throw error;
    }
}