import { APIProvider, AdvancedMarker, Map as GoogleMap } from '@vis.gl/react-google-maps';
import { FC } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { HoperfyEvent } from '../../clients/DirectusClient';
import { HotelResponse } from '../../clients/HotelsClient';
import ClusteredHotelMarker from '../ClusteredHotelMarker/ClusteredHotelMarker';


interface MapViewProps {
    hotels: HotelResponse[]
    event: HoperfyEvent,
    onClick: (hotel: HotelResponse) => void;
    activeHotelId: string
}

const MapView: FC<MapViewProps> = ({ hotels, event, onClick, activeHotelId }) => {


    return (
        <APIProvider apiKey={'AIzaSyAwe7AG6AlnSDfJ-5NZb9B8U6sKy8a99v4'}>

            <GoogleMap
                mapId={'yeet'}
                defaultCenter={{ lat: +event.latitude, lng: +event.longitude }}
                defaultZoom={16}
                gestureHandling={'greedy'}
            >
                {hotels && <ClusteredHotelMarker onClick={onClick} activeHotelId={activeHotelId} hotels={hotels} event={event} />}
                <AdvancedMarker position={{ lat: +event.latitude, lng: +event.longitude }} className='z-50'>
                    <div className="relative flex flex-col items-center">
                        {/* Text Label Above the Marker */}
                        <div
                            className="
                                absolute -top-10 
                                bg-white 
                                text-gray-800 
                                rounded-lg 
                                shadow-lg 
                                px-3 py-1
                                text-sm font-semibold 
                                whitespace-nowrap
                                border border-gray-200
                            "
                        >
                            <h2>{event.name}</h2>
                            <h3>{event.venue}</h3>
                        </div>

                        {/* Marker Icon */}
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-14 h-14 text-red-500"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    </div>
                </AdvancedMarker>
            </GoogleMap>

        </APIProvider>
    )
}

export default MapView;
