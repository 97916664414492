import { FC, useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import './EventList.css';
import { HoperfyEvent, getEvents } from '../../clients/DirectusClient';
import { Link } from 'react-router-dom';
import LoadingBar from '../LoadingBar/LoadingBar';
import Footer from "../Footer/Footer";


interface EventListProps { }


const EventList: FC<EventListProps> = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<HoperfyEvent[]>([])

  const fetchEvents = async () => {
    setLoading(true);

    try {
      const result = await getEvents();
      setEvents(result);
    } catch (error) {
      console.error('Failed to fetch events')
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <nav className="w-full pt-14 flex flex-col items-center px-10 pb-20 bg-[#e6eaf2] relative overflow-hidden">

        <div className="w-full max-w-screen-2xl">
          <div className="relative z-10 flex md:flex-row flex-col gap-10 items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="flex flex-col rounded-3xl relative group">
                <img className="h-6" src="https://assets.ycodeapp.com/assets/app26596/Images/ibe5CIqwQpm0hIoZzG501CKeBQ7PkTC1oVCXAdjl.png" alt='Hoperfy logo' />
              </div>
            </div>
          </div>
        </div>

      </nav>
      <section className="w-full max-w-screen-2xl py-6 flex flex-col gap-4">
        <h1 className="font-bold tracking-tighter text-5xl">Explore events</h1>
        <p className="text-xl max-w-screen-lg opacity-60">Discover major tech and startup events in Europe and the US. Our page is your one-stop resource for must-attend conferences, summits, and trade shows, perfect for tech enthusiasts, industry professionals, and startup founders planning their business travel.</p>
      </section>
      <section className="w-full max-w-screen-2xl grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-12">



        <LoadingBar loading={loading} />


        {events.map(event => (<main className="bg-white rounded-t-2xl flex flex-col items-center -mt-6 px-10 py-10 relative z-10">

          <Link className="flex flex-col bg-white rounded-[14px] relative group" to={`/${event.slug}/hotels`}>

            <div className="bg-neutral-100 aspect-video rounded-[14px] overflow-hidden relative">
              <img src={process.env.REACT_APP_DIRECTUS_BASE_URL + '/assets/' + event.banner} alt={event.name} className="w-full h-full object-cover rounded-[14px] group-hover:scale-[1.02] transition ease-in-out" />
            </div>

            <div className="border border-black/5 group-hover:border-black/15 rounded-[14px] absolute w-full h-full z-10"></div>

            <div className="p-6">

              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <span className="text-sm opacity-60">{event.country + ', ' + event.city}</span>
                  <div className="h-0.5 w-0.5 bg-black/30"></div>
                  <span className="text-sm opacity-60">{new Date(event.start_date).toISOString().substring(8, 10) + " - " + new Date(event.end_date).toISOString().substring(8, 10) + ', ' + new Date(event.start_date).toLocaleString('default', { month: 'long' })} {new Date(event.start_date).getFullYear()}</span>
                </div>
                <h3 className="font-medium leading-4">{event.name}</h3>
              </div>

            </div>

          </Link>


        </main>))}
      </section>

      <Footer />
    </div>
  )
}

export default EventList;
