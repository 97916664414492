import Big from 'big.js';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from 'react-router-dom';
import TripAdvisorLogo from '../../assets/owl_trip_advisor.png';
import { HoperfyEvent, getEvent } from '../../clients/DirectusClient';
import { CurrencyResponse, GeoSearchRequest, HotelResponse, Price, geoSearch, getCurrencies } from '../../clients/HotelsClient';
import { getTotalPrice } from '../../utils/PriceUtils';
import { constructUrl, getSearchParams } from '../../utils/QueryParamUtil';
import LoadingBar from '../LoadingBar/LoadingBar';
import MapView from '../MapView/MapView';
import Navigation from '../Navigation/Navigation';
import RatingDots from '../RatingDots/RatingDots';
import './HotelList.css';



interface HotelListProps { }

interface HotelDetails extends HotelResponse {
  totalStartingFrom: Price
  totalDailyStartingFrom: Price;
  distanceToVenue: number
}

interface SearchFilter {
  stars: number[]
  rating: number[]
}

enum SortingOrder {
  RECOMMENDED = "RECOMMENDED",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  RATINGS_ASC = "RATINGS_ASC",
  RATINGS_DESC = "RATINGS_DESC"
}

const HotelList: FC<HotelListProps> = () => {


  const [checkIn, setScheckIn] = useState<Date>()
  const [checkOut, setCheckOut] = useState<Date>()
  const [filter, setFilter] = useState<SearchFilter>({ stars: [], rating: [] });
  const [rooms, setRooms] = useState<{ adults: number }[]>([{ adults: 2 }]);


  const [hotels, setHotels] = useState<HotelDetails[] | null>(null);
  const [activeHotelId, setActiveHotelId] = useState<string>('')

  const [totalHotels, setTotalHotels] = useState<HotelDetails[] | null>(null);
  const [originalHotels, setOriginalHotels] = useState<HotelDetails[] | null>(null);
  const [event, setEvent] = useState<HoperfyEvent>();
  const [geoSearchRequest, setGeoSearchRequest] = useState<GeoSearchRequest>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>(SortingOrder.RECOMMENDED);
  const [showMap, setShowMap] = useState(false);
  const [showRoomPopup, setShowRoomPopup] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<CurrencyResponse[] | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('EUR');


  const popupRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setShowRoomPopup(false);
      }
    };

    if (showRoomPopup) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showRoomPopup]);

  useEffect(() => {
    const fetchCurrenciesData = async () => {
      try {
        const currenciesData = await getCurrencies();
        setCurrencies(currenciesData);
      } catch (err) {
        console.error("Failed to fetch currencies", err);
      }
    }
    fetchCurrenciesData()
  }, [])

  const { eventSlug } = useParams();


  const [error, setError] = useState<string | null>(null);

  // Create a map of refs for dynamic items
  const itemRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToItem = (id: string) => {

    const ref = itemRefs.current[id];
    const container = scrollContainerRef.current;

    if (ref && container) {
      // Calculate the position of the element relative to the container
      const containerTop = container.getBoundingClientRect().top;
      const elementTop = ref.getBoundingClientRect().top;

      // Calculate the scroll position to center the element
      const elementHeight = ref.offsetHeight;
      const containerHeight = container.offsetHeight;
      const scrollOffset =
        elementTop - containerTop + container.scrollTop - (containerHeight / 2) + (elementHeight / 2);

      // Smoothly scroll to the calculated position
      container.scrollTo({
        top: scrollOffset,
      });

      setActiveHotelId(id);
    }
  };

  const totalGuests = rooms.reduce((sum, room) => sum + room.adults, 0);

  const handleCurrencyChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    await setSelectedCurrency(e.target.value);
  };

  useEffect(() => {
    const getHotelsAndSort = async () => {
      await fetchHotels(eventSlug!)
    }
    if (selectedCurrency && hotels) {
      getHotelsAndSort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency])

  const handleAddRoom = () => {
    setRooms([...rooms, { adults: 1 }]);
  };

  const handleRemoveRoom = (index: number) => {
    setRooms(rooms.filter((_, i) => i !== index));
  };

  const handleRoomChange = (index: number, adults: number) => {
    const updatedRooms = [...rooms];
    updatedRooms[index].adults = adults;
    setRooms(updatedRooms);
  };

  const handleMarkerClick = useCallback((hotel: HotelResponse) => {
    scrollToItem(hotel.hotel.id);
  }, []);

  const handleShowMap = () => {
    setShowMap(!showMap); // Toggle visibility
  };

  const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return Math.round(d * 100) / 100;
  }

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180)
  }

  const sortHotels = (hotels: HotelDetails[]): HotelDetails[] => {
    const hotelIndexMap = new Map<HotelDetails, number>();

    originalHotels?.forEach((hotel, index) => {
      hotelIndexMap.set(hotel, index);
    });

    switch (sortingOrder) {
      case SortingOrder.PRICE_ASC:
        return hotels.sort((a, b) => a.totalStartingFrom.amount.minus(b.totalStartingFrom.amount).toNumber());
      case SortingOrder.PRICE_DESC:
        return hotels.sort((a, b) => b.totalStartingFrom.amount.minus(a.totalStartingFrom.amount).toNumber());
      case SortingOrder.DISTANCE_ASC:
        return hotels.sort((a, b) => a.distanceToVenue - b.distanceToVenue);
      case SortingOrder.DISTANCE_DESC:
        return hotels.sort((a, b) => b.distanceToVenue - a.distanceToVenue);
      case SortingOrder.RATINGS_ASC:
        return hotels.sort((a, b) => (a.hotel.rating?.rating || 0) - (b.hotel.rating?.rating || 0));
      case SortingOrder.RATINGS_DESC:
        return hotels.sort((a, b) => (b.hotel.rating?.rating || 0) - (a.hotel.rating?.rating || 0));

      default:
        return hotels.sort((a, b) => (hotelIndexMap.get(a) || 0) - (hotelIndexMap.get(b) || 0)) || hotels;
    }
  };


  const handleStarCheckboxChange = (star: number, isChecked: boolean) => {
    setFilter(prevFilter => {
      const updatedStars = isChecked
        ? [...prevFilter.stars, star] // Add star rating if checked
        : prevFilter.stars.filter(s => s !== star); // Remove star rating if unchecked
      return { ...prevFilter, stars: updatedStars };
    })
  };

  const handleRatingCheckboxChange = (rating: number, isChecked: boolean) => {
    setFilter(prevFilter => {
      const updatedRating = isChecked
        ? [...prevFilter.rating, rating] // Add star rating if checked
        : prevFilter.rating.filter(s => s !== rating); // Remove star rating if unchecked
      return { ...prevFilter, rating: updatedRating };
    })
  };



  const fetchHotels = async (slug: string) => {
    debugger
    setLoading(true);
    setError(null);


    try {
      const event = await getEvent(slug);
      setEvent(event)

      if (!checkIn) {
        setScheckIn(event.start_date)
      }
      if (!checkOut) {
        setCheckOut(event.end_date)
      }
      const newRequest = {
        longitude: event.longitude,
        latitude: event.latitude,
        radius: event.radius,
        checkIn: checkIn || event.start_date,
        checkOut: checkOut || event.end_date,
        rooms: rooms.map(room => ({ adults: room.adults, children: [] })),
        currency: selectedCurrency
      }

      setGeoSearchRequest(newRequest)

      const result = await geoSearch(newRequest);
      const enrichedResult = result.map(x => ({
        ...x,
        totalStartingFrom: getTotalPrice(x.startingFrom, event),
        totalDailyStartingFrom: getTotalPrice(x.dailyPricesStartFrom, event),
        distanceToVenue: getDistanceFromLatLonInKm(+event.latitude, +event.longitude, x.hotel.contactDTO.coordinatesDTO.latitude, x.hotel.contactDTO.coordinatesDTO.longitude)
      }) as HotelDetails).filter(x => new Big(x.dailyPricesStartFrom.amount).lte(new Big(50000)))


      setHotels(enrichedResult);
      setOriginalHotels(enrichedResult)
      setTotalHotels(enrichedResult)
    } catch (error) {
      debugger;
      setError('Error searching hotels');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHotels(eventSlug || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hotels) {
      const sortedHotels = sortHotels([...hotels]);

      setHotels(sortedHotels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingOrder, hotels]);

  useEffect(() => {
    if (!totalHotels || !Array.isArray(totalHotels)) {
      return;
    }
    let filteredHotels = [...totalHotels];

    // Apply star filters
    if (filter.stars?.length > 0) {
      filteredHotels = filteredHotels.filter(hotel => filter.stars.includes(hotel.hotel.stars));
    }

    // Apply rating filters
    if (filter.rating?.length > 0) {
      const minRating = Math.min(...filter.rating); // Find the lowest number in the array
      filteredHotels = filteredHotels.filter(hotel => (hotel.hotel.rating?.rating || 0) >= minRating);
    }

    // Sort the filtered hotels
    const sortedFilteredHotels = sortHotels(filteredHotels);

    setHotels(sortedFilteredHotels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, hotels]);


  return (
    <div className="antialiased h-screen lg:overflow-hidden flex flex-col">

      {event &&
        <Navigation event={event} />
      }

      <main className="flex-1 grow-0 bg-white rounded-t-2xl -mt-10 flex flex-col items-center py-10 relative z-10">

        <section className="w-full px-10 py-0">

          {event &&
            <h1 className="tracking-tighter text-4xl">Hotels in <span className="font-bold">{event?.city}</span> near <span className="font-bold">{event?.name}</span></h1>
          }
        </section>

        <section className="w-full px-10 mt-4 relative z-20">

          <div className="flex lg:flex-row flex-col lg:items-center lg:justify-between gap-4">

            <div className="flex-1 flex lg:flex-row flex-col lg:items-center gap-4">

              <div className="w-full lg:max-w-[240px] flex flex-col pt-3.5 relative">
                <span className="text-xs opacity-60 px-5 relative z-10">Check-in</span>
                <DatePicker className="font-medium text-sm bg-neutral-100 pb-3.5 pt-8 px-5 -mt-8 w-full focus:outline-none focus:bg-white rounded-2xl border border-transparent focus:border-neutral-200 leading-5" selected={checkIn} onChange={(date) => setScheckIn(date || checkIn)} />
              </div>

              <div className="w-full lg:max-w-[240px] flex flex-col pt-3.5 relative">
                <span className="text-xs opacity-60 px-5 relative z-10">Check-out</span>
                <DatePicker className="font-medium text-sm bg-neutral-100 pb-3.5 pt-8 px-5 -mt-8 w-full focus:outline-none focus:bg-white rounded-2xl border border-transparent focus:border-neutral-200 leading-5" selected={checkOut} onChange={(date) => setCheckOut(date || checkOut)} />
              </div>

              <div className="w-full lg:max-w-[240px] flex flex-col pt-3.5 relative">
                <span className="text-xs opacity-60 px-5 relative z-10">Guests</span>
                <button
                  className="font-medium text-sm bg-neutral-100 pb-3.5 pt-8 px-5 text-left -mt-8 w-full h-full focus:outline-none focus:bg-white rounded-2xl border border-transparent focus:border-neutral-200 leading-5"
                  onClick={() => setShowRoomPopup(true)}
                >
                  <div>
                    <strong>{rooms.length} room(s)</strong>, {totalGuests} adult(s)
                  </div>
                </button>

                {showRoomPopup && (
                  <div ref={popupRef} className="absolute top-full left-0 bg-white border border-gray-300 rounded-lg shadow-lg p-4 mt-2 z-50">
                    {rooms.map((room, index) => (
                      <div key={index} className="flex items-center gap-2 mb-2">
                        <span>Room {index + 1} Adults:</span>
                        <input
                          type="number"
                          value={room.adults}
                          pattern="^[0-9]" title='Only Number' min="1" step="1"
                          onChange={(e) => handleRoomChange(index, parseInt(e.target.value, 10))}
                          className="border rounded px-2 py-1 w-16"
                        />
                        {rooms.length > 1 && (
                          <button
                            className="text-red-500 hover:text-red-700"
                            onClick={() => handleRemoveRoom(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      className="bg-black hover:bg-black/70 text-white px-4 py-2 rounded"
                      onClick={handleAddRoom}
                    >
                      Add Room
                    </button>
                    <button
                      className="mt-2 text-gray-500 hover:text-gray-700 block"
                      onClick={() => setShowRoomPopup(false)}
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>

              <button onClick={(e: any) => fetchHotels(eventSlug || '')} className="p-5 bg-black hover:bg-black/70 cursor-pointer rounded-2xl text-white flex items-center justify-center text-sm font-medium gap-1.5 leading-5" disabled={rooms.reduce((sum, value) => sum = sum + value.adults, 0) < 1 || loading || checkIn == null || checkOut == null}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6">
                  <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z" clipRule="evenodd"></path>
                </svg>
                <span className="md:hidden text-base font-medium">Search</span>
              </button>

            </div>

            <div className="flex items-center gap-4">

              {hotels && !loading && !error &&
                <button className="hidden lg:flex items-center justify-center bg-white border border-black/10 focus-within:border-black/20 flex flex-col h-[68px] w-40 rounded-2xl" onClick={handleShowMap}>
                  <span className="px-3 py-1 rounded-full bg-black/70 text-sm text-white font-medium absolute backdrop-blur-2xl">{showMap ? 'Hide Map' : 'Show Map'}</span>
                  <img src="https://i.ibb.co/hZYhLKH/Google-maps-screenshot.png" alt='Google maps template' className="h-full w-full object-cover rounded-2xl" />
                </button>
              }
              <div className="bg-white border border-black/10 focus-within:border-black/20 flex flex-col px-5 py-3.5 rounded-2xl min-w-[220px] relative">
                <label className="text-xs opacity-70 my-px pointer-events-none">Currency</label>
                <select
                  className="appearance-none font-medium text-sm px-5 -mx-5 pb-4 pt-8 -mb-3.5 -mt-8 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5 placeholder-neutral-400 max-w-[220px] truncate"
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                >
                  {currencies &&
                    currencies.map((currency) => (
                      <option key={currency.currencyCode} value={currency.currencyCode}>
                        {currency.currencyCode}({currency.currencySymbol})
                      </option>
                    ))}
                </select>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 absolute right-4 top-[28px] opacity-30"><path d="M18 9 12 3 6 9H18ZM18 15 12 21 6 15H18Z"></path></svg>
              </div>

              <div className="bg-white border border-black/10 focus-within:border-black/20 flex flex-col px-5 py-3.5 rounded-2xl min-w-[220px] relative">
                <label className="text-xs opacity-70 my-px pointer-events-none">Sort by</label>
                <select
                  className="appearance-none font-medium text-sm px-5 -mx-5 pb-4 pt-8 -mb-3.5 -mt-8 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5 placeholder-neutral-400 max-w-[220px] truncate"
                  value={sortingOrder}
                  onChange={(e) => setSortingOrder(e.target.value as SortingOrder)}
                >
                  <option value={SortingOrder.RECOMMENDED}>Recommended</option>
                  <option value={SortingOrder.PRICE_ASC}>Price (lowest first)</option>
                  <option value={SortingOrder.PRICE_DESC}>Price (highest first)</option>
                  <option value={SortingOrder.DISTANCE_ASC}>Distance (closest first)</option>
                  <option value={SortingOrder.DISTANCE_DESC}>Distance (farthest first)</option>
                  <option value={SortingOrder.RATINGS_DESC}>Ratings (highest first)</option>
                  <option value={SortingOrder.RATINGS_ASC}>Ratings (lowest first)</option>

                </select>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 absolute right-4 top-[28px] opacity-30"><path d="M18 9 12 3 6 9H18ZM18 15 12 21 6 15H18Z"></path></svg>
              </div>

            </div>

          </div>

        </section>

        {loading && (
          <LoadingBar loading={loading} />
        )}
        {loading && (
          <section className="w-full px-10 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mt-12">

            <div className="flex flex-col bg-white rounded-3xl overflow-hidden relative group animate-pulse">
              <div className="bg-neutral-100 aspect-video rounded-[14px]"></div>
              <div className="flex-1 p-6 flex flex-col gap-2.5">

                <div className="flex items-center justify-between">
                  <div className="bg-neutral-100 h-3 w-24 rounded"></div>
                </div>

                <div className="bg-neutral-100 h-3 w-44 rounded"></div>


                <div className="mt-4 flex items-center gap-2.5">
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                  <div className="h-0.5 w-0.5 bg-black/20"></div>
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                </div>

              </div>
            </div>
            <div className="flex flex-col bg-white rounded-3xl overflow-hidden relative group animate-pulse">
              <div className="bg-neutral-100 aspect-video rounded-[14px]"></div>
              <div className="flex-1 p-6 flex flex-col gap-2.5">

                <div className="flex items-center justify-between">
                  <div className="bg-neutral-100 h-3 w-24 rounded"></div>
                </div>

                <div className="bg-neutral-100 h-3 w-44 rounded"></div>


                <div className="mt-4 flex items-center gap-2.5">
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                  <div className="h-0.5 w-0.5 bg-black/20"></div>
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                </div>

              </div>
            </div>
            <div className="flex flex-col bg-white rounded-3xl overflow-hidden relative group animate-pulse">
              <div className="bg-neutral-100 aspect-video rounded-[14px]"></div>
              <div className="flex-1 p-6 flex flex-col gap-2.5">

                <div className="flex items-center justify-between">
                  <div className="bg-neutral-100 h-3 w-24 rounded"></div>
                </div>

                <div className="bg-neutral-100 h-3 w-44 rounded"></div>


                <div className="mt-4 flex items-center gap-2.5">
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                  <div className="h-0.5 w-0.5 bg-black/20"></div>
                  <div className="bg-neutral-100 h-3 w-14 rounded"></div>
                </div>

              </div>
            </div>

          </section>
        )}


        {error && <p>{error}</p>}

        <div className={`border-t border-black/10 mt-8 ${showMap ? 'w-full flex' : 'pt-8 lg:overflow-scroll'}`} style={{ height: `${window.innerHeight - 200}px` }}>




          {hotels && !loading && !error && (
            <section className={`${showMap ? `flex pl-10` : `flex items-start w-full px-10 flex-col gap-12 lg:flex-row`}`}>

              <div className={`flex flex-col gap-4 lg:max-w-[200px] w-full lg:w-auto ${showMap ? `py-8` : `lg:sticky lg:top-0`}`}>
                <div className="flex flex-col">
                  <span className="font-medium">Property star rating</span>
                  <span className="text-sm opacity-50">Find high-quality hotels.</span>
                </div>
                <div className="flex flex-col gap-2.5">
                  <label className="flex items-center gap-2.5">
                    <input type="checkbox" className="h-5 w-5 rounded-md"
                      checked={filter.stars.includes(5)}
                      onChange={(e) => handleStarCheckboxChange(5, e.target.checked)} />
                    <span className="text-sm font-medium">5 stars</span>
                    <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x => x.hotel.stars === 5).length || 0}</span>
                  </label>
                  <label className="flex items-center gap-2.5">
                    <input type="checkbox" className="h-5 w-5 rounded-md"
                      checked={filter.stars.includes(4)}
                      onChange={(e) => handleStarCheckboxChange(4, e.target.checked)} />
                    <span className="text-sm font-medium">4 stars</span>
                    <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x => x.hotel.stars === 4).length || 0}</span>
                  </label>
                  <label className="flex items-center gap-2.5">
                    <input type="checkbox" className="h-5 w-5 rounded-md"
                      checked={filter.stars.includes(3)}
                      onChange={(e) => handleStarCheckboxChange(3, e.target.checked)} />
                    <span className="text-sm font-medium">3 stars</span>
                    <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x => x.hotel.stars === 3).length || 0}</span>
                  </label>
                  <label className="flex items-center gap-2.5">
                    <input type="checkbox" className="h-5 w-5 rounded-md"
                      checked={filter.stars.includes(2)}
                      onChange={(e) => handleStarCheckboxChange(2, e.target.checked)} />
                    <span className="text-sm font-medium">2 stars</span>
                    <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x => x.hotel.stars === 2).length || 0}</span>
                  </label>
                  <label className="flex items-center gap-2.5">
                    <input type="checkbox" className="h-5 w-5 rounded-md"
                      checked={filter.stars.includes(1)}
                      onChange={(e) => handleStarCheckboxChange(1, e.target.checked)} />
                    <span className="text-sm font-medium">1 star</span>
                    <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x => x.hotel.stars === 1).length || 0}</span>
                  </label>
                </div>

                <div className="flex flex-col">
                  <span className="font-medium">Property rating</span>
                  <span className="text-sm opacity-50">Find popular hotels.</span>
                </div>
                <div className="flex flex-col gap-2.5">
                  <label className="flex items-center gap-2.5">
                    <input type="checkbox" className="h-5 w-5 rounded-md"
                      checked={filter.rating.includes(4.5)}
                      onChange={(e) => handleRatingCheckboxChange(4.5, e.target.checked)} />
                    <span className="text-sm font-medium">4.5+ Excellent</span>
                    <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x => x.hotel.rating?.rating && x.hotel.rating?.rating >= 4.5).length || 0}</span>
                  </label>
                  <label className="flex items-center gap-2.5">
                    <input type="checkbox" className="h-5 w-5 rounded-md"
                      checked={filter.rating.includes(4)}
                      onChange={(e) => handleRatingCheckboxChange(4, e.target.checked)} />
                    <span className="text-sm font-medium">4+ superb</span>
                    <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x => x.hotel.rating?.rating && x.hotel.rating?.rating >= 4).length || 0}</span>
                  </label>
                  <label className="flex items-center gap-2.5">
                    <input type="checkbox" className="h-5 w-5 rounded-md"
                      checked={filter.rating.includes(3.5)}
                      onChange={(e) => handleRatingCheckboxChange(3.5, e.target.checked)} />
                    <span className="text-sm font-medium">3.5+ good</span>
                    <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x => x.hotel.rating?.rating && x.hotel.rating?.rating >= 3.5).length || 0}</span>
                  </label>
                  <label className="flex items-center gap-2.5">
                    <input type="checkbox" className="h-5 w-5 rounded-md"
                      checked={filter.rating.includes(3)}
                      onChange={(e) => handleRatingCheckboxChange(3, e.target.checked)} />
                    <span className="text-sm font-medium">3+ pleasant</span>
                    <span className="ml-auto text-sm opacity-50">{totalHotels?.filter(x => x.hotel.rating?.rating && x.hotel.rating?.rating >= 3).length || 0}</span>
                  </label>
                </div>
              </div>

              {showMap ?
                <div className="flex-1 flex flex-col max-w-[440px] gap-5 overflow-scroll py-8 pb-40 pl-10 pr-5"
                  style={{ height: `${window.innerHeight - 200}px` }}
                  ref={scrollContainerRef}
                >
                  {hotels.length > 0 ? (
                    hotels.map((hotel, index) => (
                      <div
                        ref={(el) => (itemRefs.current[hotel.hotel.id] = el)} // Dynamically assign refs
                      >
                        <Link className={`flex flex-col bg-white rounded-[14px] relative group ${activeHotelId === hotel.hotel.id
                          ? "ring-2 ring-blue-500 ring-offset-4" // Apply classes when active
                          : ""}`} key={`/${event?.slug}/hotel/${hotel.hotel.id}`} to={`/${event?.slug}/hotel/${hotel.hotel.id}?${getSearchParams(geoSearchRequest).toString()}`}>

                          <div className="bg-neutral-100 aspect-video rounded-[14px] overflow-hidden relative">
                            <div className="h-1/2 w-full bg-gradient-to-t from-black opacity-30 absolute bottom-0 z-10" />
                            {hotel.hotel.stars > 0 &&
                              <div className="flex items-center gap-0.5 absolute bottom-5 left-6 bg-white rounded-lg py-1 px-1.5 z-20 text-amber-300">
                                {Array.from(Array(hotel.hotel.stars), (e, i) => {
                                  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-3.5">
                                    <path fillRule="evenodd" d="M8 1.75a.75.75 0 0 1 .692.462l1.41 3.393 3.664.293a.75.75 0 0 1 .428 1.317l-2.791 2.39.853 3.575a.75.75 0 0 1-1.12.814L7.998 12.08l-3.135 1.915a.75.75 0 0 1-1.12-.814l.852-3.574-2.79-2.39a.75.75 0 0 1 .427-1.318l3.663-.293 1.41-3.393A.75.75 0 0 1 8 1.75Z" clipRule="evenodd"></path>
                                  </svg>
                                })}

                              </div>
                            }
                            <img src={constructUrl('1024x768', hotel.hotel.images[0])} alt={hotel.hotel.name} className="w-full h-full object-cover rounded-[14px] group-hover:scale-[1.02] transition ease-in-out" />
                            {hotel.hotel.rating && (
                              <div className="absolute top-2 right-2 bg-white p-1 rounded-lg shadow-md flex items-center gap-1">
                                <img
                                  src={TripAdvisorLogo}
                                  alt="TripAdvisor"
                                  className="h-5 w-5" // Increased the size of the logo
                                />
                                <RatingDots rating={hotel.hotel.rating.rating} />
                              </div>
                            )}
                          </div>

                          <div className="border border-black/5 group-hover:border-black/15 rounded-[14px] absolute w-full h-full z-10"></div>

                          <div className="flex-1 p-6 flex flex-col gap-1">

                            <div className="">
                              <h3 className="inline font-medium leading-4">{hotel.hotel.name}</h3>
                            </div>

                            {event && hotel.hotel.contactDTO.coordinatesDTO.latitude && hotel.hotel.contactDTO.coordinatesDTO.longitude &&
                              <div className="">
                                <span className="text-sm opacity-60">{hotel.distanceToVenue}km away from the venue</span>
                              </div>
                            }

                            <div className="flex items-center gap-2 mt-auto pt-3">
                              <span className="text-sm opacity-60">{hotel.totalDailyStartingFrom.currency.currencySymbol}{hotel.totalDailyStartingFrom.amount.toString()} night</span>
                              <div className="h-0.5 w-0.5 bg-black/30"></div>
                              <span className="text-sm"><span className="font-medium">{hotel.totalDailyStartingFrom.currency.currencySymbol}{hotel.totalStartingFrom.amount.toString()}</span> total</span>
                            </div>

                          </div>

                        </Link>
                      </div>

                    )))
                    : (
                      <div className="text-center text-gray-500">No hotels found.</div>
                    )
                  }

                </div>
                :
                <div className="grid flex-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 overflow-scroll"
                  style={{ paddingBottom: '100px' }}>

                  {hotels.length > 0 ? (
                    hotels.map((hotel) => (

                      <Link className="flex flex-col bg-white rounded-[14px] relative group" key={`/${event?.slug}/hotel/${hotel.hotel.id}`} to={`/${event?.slug}/hotel/${hotel.hotel.id}?${getSearchParams(geoSearchRequest).toString()}`}>

                        <div className="bg-neutral-100 aspect-video rounded-[14px] overflow-hidden relative">
                          <div className="h-1/2 w-full bg-gradient-to-t from-black opacity-30 absolute bottom-0 z-10" />
                          {hotel.hotel.stars > 0 &&
                            <div className="flex items-center gap-0.5 absolute bottom-5 left-6 bg-white rounded-lg py-1 px-1.5 z-20 text-amber-300">
                              {Array.from(Array(hotel.hotel.stars), (e, i) => {
                                return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-3.5">
                                  <path fillRule="evenodd" d="M8 1.75a.75.75 0 0 1 .692.462l1.41 3.393 3.664.293a.75.75 0 0 1 .428 1.317l-2.791 2.39.853 3.575a.75.75 0 0 1-1.12.814L7.998 12.08l-3.135 1.915a.75.75 0 0 1-1.12-.814l.852-3.574-2.79-2.39a.75.75 0 0 1 .427-1.318l3.663-.293 1.41-3.393A.75.75 0 0 1 8 1.75Z" clipRule="evenodd"></path>
                                </svg>
                              })}

                            </div>
                          }
                          <img src={constructUrl('1024x768', hotel.hotel.images[0])} alt={hotel.hotel.name} className="w-full h-full object-cover rounded-[14px] group-hover:scale-[1.02] transition ease-in-out" />
                          {hotel.hotel.rating && (
                            <div className="absolute top-2 right-2 bg-white p-1 rounded-lg shadow-md flex items-center gap-1">
                              <img
                                src={TripAdvisorLogo}
                                alt="TripAdvisor"
                                className="h-5 w-5" // Increased the size of the logo
                              />
                              <RatingDots rating={hotel.hotel.rating.rating} />
                            </div>
                          )}
                        </div>

                        <div className="border border-black/5 group-hover:border-black/15 rounded-[14px] absolute w-full h-full z-10"></div>

                        <div className="flex-1 p-6 flex flex-col gap-1">

                          <div className="">
                            <h3 className="inline font-medium leading-4">{hotel.hotel.name}</h3>
                          </div>

                          {event && hotel.hotel.contactDTO.coordinatesDTO.latitude && hotel.hotel.contactDTO.coordinatesDTO.longitude &&
                            <div className="">
                              <span className="text-sm opacity-60">{hotel.distanceToVenue}km away from the venue</span>
                            </div>
                          }

                          <div className="flex items-center gap-2 mt-auto pt-3">
                            <span className="text-sm opacity-60">{hotel.totalDailyStartingFrom.currency.currencySymbol}{hotel.totalDailyStartingFrom.amount.toString()} night</span>
                            <div className="h-0.5 w-0.5 bg-black/30"></div>
                            <span className="text-sm"><span className="font-medium">{hotel.totalDailyStartingFrom.currency.currencySymbol}{hotel.totalStartingFrom.amount.toString()}</span> total</span>
                          </div>

                        </div>

                      </Link>
                    ))
                  ) : (
                    <div className="flex items-center justify-center h-full text-gray-500">No hotels found.</div>
                    )}
                </div>
              }


            </section>

          )}


          {hotels && event && !loading && !error &&

            <div className="pl-5 w-2/3">

              {showMap && <div className="w-full" style={{ height: `${window.innerHeight - 300}px` }}>
                <MapView hotels={hotels} event={event} onClick={handleMarkerClick} activeHotelId={activeHotelId} ></MapView>
              </div>

              }

            </div>

          }

        </div>

      </main>

    </div>
  )
}

export default HotelList;
