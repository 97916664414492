import { FC, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SelectedHotel } from '../../types/SelectedHotel';
import { calculateNights } from '../../utils/DateUtils';
import { HoperfyEvent, createOrder } from '../../clients/DirectusClient';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import LoadingBar from '../LoadingBar/LoadingBar';
import { constructUrl } from '../../utils/QueryParamUtil';
import { BaseSearchRequest, Price } from '../../clients/HotelsClient';


interface CheckoutProps {
    selectedHotel: SelectedHotel,
    totalPrice: Price,
    clientSecret: string,
    event: HoperfyEvent
    search: BaseSearchRequest
}

const Checkout: FC<CheckoutProps> = ({ selectedHotel, totalPrice, clientSecret, event, search }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState<boolean>(false)


    const handlePaymentChange = (event: StripePaymentElementChangeEvent) => {
        setPaymentDataComplete(event.complete)
    }


    const allGuests = search.rooms.flatMap((room, roomIndex) =>
        selectedHotel.rooms.flatMap((selectedRoom, selectedIndex) =>
            Array.from({ length: selectedRoom.amount }).map((_, guestIndex) => ({
                roomIndex,
                selectedIndex,
                guestIndex,
                adults: room.adults
            }))
        )
    );

    const [formData, setFormData] = useState({
        guests: allGuests.map(x => ({
            firstName: '',
            lastName: '',
            nationality: '',
            number: x.adults
        })),
        email: '',
        phone: '',
        additionalRequests: '',
        stripePaymentId: ''
    });

    const [paymentDataComplete, setPaymentDataComplete] = useState<boolean>(false)

    const isSubmitValid = () => {
        return paymentDataComplete && formData.guests[0].firstName && formData.guests[0].lastName
            && formData.email.includes('@') && formData.phone
            && formData.guests[0].nationality
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleGuestInputChange = (index: number, fieldName: string, value: string) => {
        setFormData((prevState) => ({
            ...prevState,
            guests: prevState.guests.map((guest, i) =>
                i === index ? { ...guest, [fieldName]: value } : guest
            ),
        }));
    }

    const handleSubmit = async (buttonEvent: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        buttonEvent.preventDefault();


        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (!isSubmitValid()) {
            return;
        }

        const submitResult = await elements.submit()
        if (submitResult.error) {
            return
        }

        setLoading(true)

        try {
            const paymentIntent = await stripe.retrievePaymentIntent(clientSecret);

            await createOrder({
                guests: formData.guests.map(x => ({
                    firstName: x.firstName,
                    lastName: x.lastName,
                    nationality: x.nationality,
                    number: x.number
                })),
                email: formData.email,
                phone: formData.phone,
                additionalRequests: formData.additionalRequests,
                paymentId: paymentIntent.paymentIntent!.id,
                search: selectedHotel.search,
                hotelId: selectedHotel.hotel.id,
                rooms: selectedHotel.rooms.map(x => ({
                    roomId: x.roomId,
                    amount: x.amount
                })),
                eventId: event.id
            })


            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: `${process.env.REACT_APP_BASE_URL}/${event.slug}/success`,
                },
            });



            if (result.error) {

                // Show error to your customer (for example, payment details incomplete)
                console.error(result.error.message);
                window.location.reload();
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        } catch (err) {
            console.log("error", err)
            window.location.reload();
        } finally {
            setLoading(false)
        }

    };


    return (
        <div>
            <LoadingBar loading={loading} />
            <main className="flex flex-col items-center px-10 py-10">
                <section className="w-full max-w-screen-xl py-6 mx-auto">
                    <form className="flex lg:flex-row flex-col items-start gap-20" onSubmit={handleSubmit}>
                        <div className="flex-1 flex flex-col">
                            <div className="flex flex-col gap-8">
                                <div className="flex flex-col gap-8">
                                    <header className="flex flex-col gap-0.5">
                                        <span className="text-2xl font-semibold">Information about guests</span>
                                        <span className="opacity-50">Please provide the main guest details for each room.</span>
                                    </header>


                                    {allGuests.map((guest, index) => (
                                        <div className="flex flex-col gap-6">
                                             <h2 className="text-2xl font-semibold text-gray-800">Room {index + 1}</h2>
                                            <div className="grid grid-cols-2 gap-6">
                                                <div className="bg-white border border-black/10 focus-within:border-black/20 flex flex-col px-5 py-3.5 rounded-2xl relative">
                                                    <label className="text-xs opacity-70 my-px pointer-events-none">Citizenship*</label>
                                                    <input required
                                                        name="nationality"
                                                        value={formData.guests[index].nationality}
                                                        onChange={(e) => handleGuestInputChange(index, "nationality", e.target.value)}
                                                        placeholder="e.g. Lithuanian" type="text"
                                                        className="font-medium text-sm px-5 -mx-5 pb-4 pt-8 -mb-3.5 -mt-8 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5 placeholder-neutral-400" />
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2 gap-6">
                                                <div className="bg-white border border-black/10 focus-within:border-black/20 flex flex-col px-5 py-3.5 rounded-2xl">
                                                    <label className="text-xs opacity-70 my-px pointer-events-none">First name*</label>
                                                    <input
                                                        required
                                                        name="firstName"
                                                        value={formData.guests[index].firstName || ''}
                                                        onChange={(e) => handleGuestInputChange(index, "firstName", e.target.value)}
                                                        type="text"
                                                        placeholder="e.g. John"
                                                        className="font-medium text-sm px-5 -mx-5 pb-4 pt-8 -mb-3.5 -mt-8 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5 placeholder-neutral-400"
                                                    />
                                                </div>
                                                <div className="bg-white border border-black/10 focus-within:border-black/20 flex flex-col px-5 py-3.5 rounded-2xl">
                                                    <label className="text-xs opacity-70 my-px pointer-events-none">Last name*</label>
                                                    <input
                                                        required
                                                        name="lastName"
                                                        placeholder="e.g. Smith"
                                                        type="text"
                                                        value={formData.guests[index].lastName || ''}
                                                        onChange={(e) => handleGuestInputChange(index, "lastName", e.target.value)}
                                                        className="font-medium text-sm px-5 -mx-5 pb-4 pt-8 -mb-3.5 -mt-8 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5 placeholder-neutral-400"
                                                    />
                                                </div>
                                            </div>
                                            <hr className="border-neutral-100 my-2" />
                                        </div>
                                    ))}
                                </div>
                                <div className="flex flex-col gap-8">
                                    <header>
                                        <span className="text-2xl font-semibold">Booking details</span>
                                    </header>
                                    <div className="flex flex-col gap-6">
                                        <div className="grid grid-cols-2 gap-6">
                                            <div className="bg-white border border-black/10 focus-within:border-black/20 flex flex-col px-5 py-3.5 rounded-2xl">
                                                <label className="text-xs opacity-70 my-px pointer-events-none">Email address*</label>
                                                <input required
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    placeholder="e.g. john@example.com" type="email" className="font-medium text-sm px-5 -mx-5 pb-4 pt-8 -mb-3.5 -mt-8 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5 placeholder-neutral-400" />
                                            </div>
                                            <div className="bg-white border border-black/10 focus-within:border-black/20 flex flex-col px-5 py-3.5 rounded-2xl">
                                                <label className="text-xs opacity-70 my-px pointer-events-none">Phone number*</label>
                                                <input
                                                    required
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleInputChange}
                                                    placeholder="+123-456-7890" type="phone" className="font-medium text-sm px-5 -mx-5 pb-4 pt-8 -mb-3.5 -mt-8 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5 placeholder-neutral-400" />
                                            </div>
                                        </div>
                                        <div className="bg-white border border-black/10 focus-within:border-black/20 flex flex-col px-5 py-3.5 rounded-2xl">
                                            <label className="text-xs opacity-70 my-px pointer-events-none">Additional requests</label>
                                            <textarea placeholder="Add any special requests if needed."
                                                name="additionalRequests"
                                                value={formData.additionalRequests}
                                                onChange={handleInputChange}
                                                rows={5} className="font-medium text-sm px-5 -mx-5 pb-4 pt-8 -mb-3.5 -mt-8 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5 placeholder-neutral-400" />
                                        </div>
                                    </div>
                                </div>
                                <hr className="border-neutral-100 my-2" />
                                <div className="flex flex-col gap-8">
                                    <header>
                                        <span className="text-2xl font-semibold">Payment details</span>
                                    </header>
                                    <PaymentElement id='payment-element' onChange={handlePaymentChange} />
                                </div>
                                <div className="flex items-center justify-between">
                                    <span className="font-medium">Payment total</span>
                                    <span className="text-2xl font-semibold tracking-tight">{totalPrice.currency.currencySymbol} {totalPrice.amount.toString()}</span>
                                </div>
                                <hr className="border-neutral-100 my-2" />
                                <span className="text-xs text-center opacity-50">By pressing "Confirm Payment", you agree to <a href="/" className="underline">Hoperfy Terms of Use</a>, and have read and understood our <a href="/" className="underline">Privacy Policy</a>.</span>
                            </div>
                        </div>
                        <div className="flex-1 w-full lg:max-w-[400px]">
                            <aside className="w-full flex flex-col relative">
                                <div className="border border-black/5 rounded-[14px] absolute w-full h-full z-10 pointer-events-none"></div>
                                <div className="bg-neutral-100 aspect-video rounded-[14px] overflow-hidden relative">
                                    <img src={constructUrl('1024x768', selectedHotel.hotel.images[0])} alt="hotel" className="w-full h-full object-cover rounded-[14px]" />
                                </div>
                                <div className="p-6 flex flex-col gap-4">
                                    <div>
                                        <h3 className="font-medium leading-4">{selectedHotel.hotel.name}</h3>
                                        <span className="text-sm opacity-60">{selectedHotel.hotel.contactDTO.address}</span>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex items-center gap-2.5">
                                            <div className="px-4 py-3.5 flex flex-col flex-1 bg-neutral-100 rounded-xl cursor-not-allowed">
                                                <span className="text-xs opacity-60">Check-in</span>
                                                <span className="text-sm font-medium">{new Date(selectedHotel.search.checkIn).toISOString().substring(0, 10)}</span>
                                                <span className="text-xs opacity-60">{selectedHotel.hotel?.checkInTime.toString().substring(0, 5)}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 opacity-50">
                                                <path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                            </svg>
                                            <div className="px-4 py-3.5 flex flex-col flex-1 bg-neutral-100 rounded-xl cursor-not-allowed">
                                                <span className="text-xs opacity-60">Check-out</span>
                                                <span className="text-sm font-medium">{new Date(selectedHotel.search.checkOut).toISOString().substring(0, 10)}</span>
                                                <span className="text-xs opacity-60">{selectedHotel.hotel?.checkOutTime.toString().substring(0, 5)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-neutral-100 my-2" />
                                    <ul>
                                        {selectedHotel.rooms.map(selectedRoom =>
                                            Array.from({ length: selectedRoom.amount }).map((_, index) => (
                                                <li className="flex items-center justify-between gap-10 py-6">
                                                    <div className="flex flex-col">
                                                        <span className="font-medium leading-4 mb-1.5">{search.rooms.length} x {selectedRoom.roomName}</span>
                                                        <div className="flex flex-wrap gap-2 items-center mt-2 mb-2">
                                                            {search.rooms.map((room, index) => (
                                                                <div
                                                                    key={`room-${index}`}
                                                                    className="flex items-center gap-1 border border-black p-1 rounded"
                                                                >
                                                                    {Array.from({ length: room.adults }).map((_, i) => (
                                                                        <svg
                                                                            key={`adult-icon-${index}-${i}`}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24"
                                                                            fill="currentColor"
                                                                            className="h-4 w-4 text-black"
                                                                        >
                                                                            <path d="M12 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm-7 9a1 1 0 0 1-1-1v-2c0-2.21 1.79-4 4-4h8c2.21 0 4 1.79 4 4v2a1 1 0 0 1-1 1H5Z" />
                                                                        </svg>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="text-xs opacity-60">
                                                            {selectedRoom.freeCancellationUntil ?
                                                                <span>Free cancellation until: {new Date(selectedRoom.freeCancellationUntil).getDate()} {new Date(selectedRoom.freeCancellationUntil).toLocaleString('en-US', { month: 'short' })} {new Date(selectedRoom.freeCancellationUntil).getFullYear()}</span>
                                                                :
                                                                <span>No free cancellation</span>
                                                            }
                                                        </div>

                                                        <span className="py-1.5 flex items-center gap-0.5 text-sm opacity-60">
                                                            <span>{selectedRoom.totalPricePerNight.currency.currencySymbol} {selectedRoom.totalPricePerNight.amount.toString()}</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-3.5">
                                                                <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                                                            </svg>
                                                            <span>{calculateNights(selectedHotel.search.checkIn, selectedHotel.search.checkOut)} nights</span>
                                                        </span>
                                                        <div className="flex flex-wrap gap-2">
                                                            {selectedRoom.breakfastIncluded ?
                                                                <div className="flex items-center gap-1 text-xs font-medium text-neutral-600 bg-black/5 px-1.5 py-0.5 rounded-lg">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 opacity-70"><path d="M21 2V22H19V14H16V7C16 4.23858 18.2386 2 21 2ZM9 13.9V22H7V13.9C4.71776 13.4367 3 11.419 3 9V3H5V10H7V3H9V10H11V3H13V9C13 11.419 11.2822 13.4367 9 13.9Z"></path></svg>
                                                                    <span>Breakfast included</span>
                                                                </div>
                                                                : <div className="flex items-center gap-1 text-xs font-medium text-neutral-600 bg-black/5 px-1.5 py-0.5 rounded-lg">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 opacity-70"><path d="M21 2V22H19V14H16V7C16 4.23858 18.2386 2 21 2ZM9 13.9V22H7V13.9C4.71776 13.4367 3 11.419 3 9V3H5V10H7V3H9V10H11V3H13V9C13 11.419 11.2822 13.4367 9 13.9Z"></path></svg>
                                                                    <span>Breakfast included</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <span className="shrink-0 font-medium">{selectedRoom.totalPrice.currency.currencySymbol} {selectedRoom.totalPrice.amount.toString()}</span>
                                                </li>
                                            )))}
                                    </ul>
                                    <hr className="border-neutral-100 my-2" />
                                    <div className="flex items-center justify-between">
                                        <span className="font-medium">Payment total</span>
                                        <span className="text-2xl font-semibold tracking-tight">{totalPrice.currency.currencySymbol} {totalPrice.amount.toString()}</span>
                                    </div>
                                    <hr className="border-neutral-100 my-2" />
                                    <button disabled={!stripe || !isSubmitValid()} className="bg-[#1a56db] z-100 p-4 text-white rounded-xl font-medium tracking-wide">
                                        Confirm payment
                                    </button>
                                </div>
                            </aside>
                            <div className="flex items-center justify-center gap-1 py-6 opacity-50">
                                <span className="font-medium text-xs">Powered by</span>
                                <img className="h-3.5 -mb-0.5" src="https://develop.travel.hoperfy.com/logo.png" alt="Hoperfy logo" />
                            </div>
                        </div>
                    </form>
                </section>
            </main>
        </div >
    );
}

export default Checkout;
