import { createDirectus, customEndpoint, readItems, rest } from "@directus/sdk";
import { UUID } from "crypto";
import { BaseSearchRequest, Price } from "./HotelsClient";



export interface PaymentIntentRequest {
    hotelId: UUID
    rooms: RoomAmount[],
    search: BaseSearchRequest
    eventId: UUID
}

export interface RoomAmount {
    roomId: UUID,
    amount: number
}

interface PaymentIntentResponse {
    clientSecret: string,
    price: Price
}


interface OrderRequest {
    guests: GuestInfo[]
    email: string
    phone: string
    additionalRequests: string,
    paymentId: string
    search: BaseSearchRequest
    hotelId: UUID
    rooms: RoomAmount[]
    eventId: UUID
}

interface FeedbackRequest {
    email: string
    title: string
    message: string
}
export interface GuestInfo {
    nationality: string
    firstName: string
    lastName: string
}
interface OrderResponse {
    orderId: UUID
}

export interface HoperfyEvent {
    id: UUID
    name: string
    slug: string
    start_date: Date
    end_date: Date
    latitude: string
    longitude: string
    radius: number
    banner: string
    logo: string
    city: string
    country: string
    venue: string
    fee_percentage: number
    logo_css_class: string
    cover_color: string
    banner_opacity: string;
}

interface DirectusSchema {
    event: HoperfyEvent[];
}

const client = createDirectus<DirectusSchema>(process.env.REACT_APP_DIRECTUS_BASE_URL || '')
    .with(rest());

export const createPaymentIntent = async (paymentIntentRequest: PaymentIntentRequest): Promise<PaymentIntentResponse> => {
    const response = await client.request(customEndpoint<PaymentIntentResponse>({
        path: '/order_endpoint/create-payment-intent',
        method: 'POST',
        body: JSON.stringify(paymentIntentRequest)
    }));

    return response;
}

export const createOrder = async (orderRequest: OrderRequest): Promise<OrderResponse> => {
    const response = await client.request(customEndpoint<OrderResponse>({
        path: '/order_endpoint/create-order',
        method: 'POST',
        body: JSON.stringify(orderRequest)
    }));

    return response;
}

export const createFeedback = async (feedbackRequest: FeedbackRequest): Promise<void> => {
    await client.request(customEndpoint<void>({
        path: '/order_endpoint/create-feedback',
        method: 'POST',
        body: JSON.stringify(feedbackRequest)
    }));

}


export const getEvents = async (): Promise<HoperfyEvent[]> => {
    const result = await client.request(readItems('event'));

    return result;
}

export const getEvent = async (slug: string): Promise<HoperfyEvent> => {
    const result = await client.request(readItems('event', {
        filter: {
            slug: {
                _eq: slug,
            }
        }
    }))

    return result[0];
}